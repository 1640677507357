import {
  Alert,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, type SubmitHandler } from "react-hook-form";
import ReactJson from "react-json-view";
import {
  useImportCrownDriversMutation,
  useMeQuery,
} from "../../generated/graphql";
import useImportDriversForm, {
  type DriverImporterFormValues,
} from "./use-driver-importer-form";

const DriversImporter = () => {
  const [importCrownDrivers, { loading: importCrownDriversLoading }] =
    useImportCrownDriversMutation();
  const { data: meData } = useMeQuery({
    fetchPolicy: "cache-first",
  });
  const company = meData?.me?.company;

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useImportDriversForm();

  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const DEFAULT_MESSAGE = "Error importing drivers";
  const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);
  const [results, setResults] = useState({});

  useEffect(() => {
    reset({
      driversUrl: "",
    });
  }, [reset]);

  const onSubmit: SubmitHandler<DriverImporterFormValues> = async (data) => {
    const { driversUrl } = data;

    try {
      const response = await importCrownDrivers({
        variables: {
          importCrownDriversInput: {
            driversUrl,
          },
        },
      });
      const { errors: resErrors } = response;
      if (isEmpty(resErrors)) {
        setResults(response.data?.importCrownDrivers ?? {});
        setSuccessVisible(true);
      } else {
        setErrorMessage(
          resErrors?.map((err) => err.message).join(", ") ?? DEFAULT_MESSAGE,
        );
        setErrorVisible(true);
      }
    } catch (error) {
      setErrorVisible(true);
      setErrorMessage(`Error: ${error}`);

      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Grid container spacing={1} sx={{ padding: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessVisible(false);
          }}
        >
          Successfully imported drivers
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorVisible(false);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4">Crown Drivers Importer</Typography>
        <Typography variant="h5">Company: {company?.name}</Typography>
        <Typography variant="subtitle1">
          PLEASE MAKE SURE YOU LOGGED IN WITH THE COMPANY ACCOUNT THAT YOU WANT
          TO CREATE THE DRIVERS FOR
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="driversUrl"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>
                Drivers URL (
                <a href="https://misc-hosting-cashew.s3.us-west-1.amazonaws.com/crown_driver_sample_import_data.csv">
                  Download sample
                </a>
                )
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                error={!isNil(errors.driversUrl)}
                value={value}
                onChange={onChange}
              />
              {!isNil(errors.driversUrl) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.driversUrl.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Button
          disabled={importCrownDriversLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Import Drivers
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ReactJson src={results} />
      </Grid>
    </Grid>
  );
};

export default DriversImporter;
