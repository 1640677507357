import { isEmpty, isNil } from 'lodash';

export const allEqual = <T>(array: T[]): boolean => {
  return array.every((v) => v === array[0]);
};

/**
 * Convert an array of nilable T to non-nilable T
 *
 * @param array
 */
export const filterNotNil = <T>(array: Array<T | undefined | null>): T[] => {
  return array.filter((v): v is T => !isNil(v));
};

export const uniqueVals = <T>(array: T[]): T[] => {
  return array.filter((c, index) => {
    return array.indexOf(c) === index;
  });
};

export const uniqueNotNilVals = <T>(
  array: Array<T | undefined | null>,
): T[] => {
  return uniqueVals(filterNotNil(array));
};

export const filterNotNilOrEmpty = <T>(
  array: Array<T | undefined | null>,
): T[] => {
  return array.flatMap((v) => (isNil(v) || isEmpty(v) ? [] : [v]));
};

export const findWithIndex = <T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean,
): { index: number; value: T } | undefined => {
  const index = array.findIndex(predicate);
  return index >= 0 ? { index, value: array[index] } : undefined;
};

/**
 * Check if a value is null, undefined, or an empty array
 *
 * @param array The array to check
 * @returns true if the array is null, undefined, or empty, false otherwise
 */
export const isNilOrEmptyArray = <T>(
  array: T[] | null | undefined,
): array is null | undefined | (T[] & { length: 0 }) => {
  return isNil(array) || isEmpty(array);
};

/**
 * Cross product of two arrays
 *
 * @param array1 The first array
 * @param array2 The second array
 * @returns The cross product of the two arrays
 */
export const crossProduct = <T, U>(array1: T[], array2: U[]): Array<[T, U]> => {
  return array1.flatMap((a) => array2.map((b): [T, U] => [a, b]));
};
